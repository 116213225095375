import React from "react";
import { Link, useParams } from "react-router-dom";
import ErrorInfo from "../../../components/error";
import Loader from "../../../components/loader";
import useClassInfo from "../../../modules/teacher/hooks/use-class-info";
import { getClassListRoute, getRulesListRoute, getClassRaportRoute } from "../../routes";
import './style.scss';

export default function ClassPanelPage() {

    const { id } = useParams<{ id: string }>();
    const { info, isLoading, error } = useClassInfo(id);

    return <main className="class-panel-page">
        <div className="summary-top-gfx"></div>
        <div className="content">
            {isLoading && <Loader />}
            {!isLoading && <>
                {error && <ErrorInfo />}
                {info && <>
                    <h1 className="heading-decor">Klasa: {info.name}</h1>
                    <div className="links">
                        <Link className="button" to={getClassListRoute(info.id)}>Lista uczniów</Link><br />
                        <Link className="button" to={getRulesListRoute(info.id)}>Lista zasad</Link><br />
                        <Link className="button" to={getClassRaportRoute(info.id)}>Raport klasy</Link>
                    </div>
                </>}
            </>}
        </div>
    </main>
}
