import React from "react";
import { useParams } from "react-router";
import ErrorInfo from "../../../components/error";
import Loader from "../../../components/loader";
import useRulesList from "../../../modules/teacher/hooks/use-rules-list";
import './style.scss';

export default function RulesListPage() {

    const { id } = useParams<{ id: string }>()

    const { data, isLoading, error } = useRulesList(id);

    return <main className='rules-list-page'>
        <div className="summary-top-gfx"></div>
        <div className="content">
            <h1 className="heading-decor">Zasady podane przez klasę</h1>
            {isLoading && <div className="loader-container">
                <Loader />
            </div>}
            {!isLoading && <>
                {error && <div className="error-container">
                    <ErrorInfo />
                </div>}
                {data && <div className="rules">
                    <ul>
                        {data.map((r: any, index) => {
                            return <li key={index}>
                                <div className="wrapper">
                                    <h3>{r.user}</h3>
                                    <p>{r.rule}</p>
                                </div>
                            </li>
                        })}
                    </ul>
                </div>}
            </>}
        </div>
    </main>
}
