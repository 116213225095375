import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useAccount, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { AzureProvider, useSetAccessToken } from ".";
import Loader from "../../components/loader";
import { CONFIG } from "../../config";


const msalConfig: Configuration = {
    auth: {
        clientId: CONFIG.AZURE.CLIENT_ID,
        authority: `https://login.microsoftonline.com/${CONFIG.AZURE.TENANT_ID}`,
    }
};

const AzureContainer: React.FC<{}> = ({ children }) => {
    const msalInstance = new PublicClientApplication(msalConfig);

    return <MsalProvider instance={msalInstance}>
        <AzureProvider>
            <AzureAutoLogin>
                {children}
            </AzureAutoLogin>
        </AzureProvider>
    </MsalProvider>
}

export default AzureContainer;

const AzureAutoLogin: React.FC<{}> = ({ children }) => {

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const setAccessToken = useSetAccessToken();
    const [showContent, setShowContent] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (inProgress === "none") {
            if (!account) {
                setShowContent(true)
            } else {
                // Retrieve an access token
                instance.acquireTokenSilent({
                    account,
                    scopes: ["User.Read"]
                }).then(response => {
                    if (response.accessToken) {
                        setAccessToken(response.accessToken);
                    }
                    queryClient.invalidateQueries('get-user');
                    queryClient.fetchQuery('get-user');
                    setShowContent(true);
                }).catch(() => {
                    setShowContent(true);
                });
            }
        }
    }, [inProgress, account, instance, setAccessToken, setShowContent, queryClient]);

return <>
    {!showContent && <Loader />}
    {showContent && <>{children}</>}
</>
}
