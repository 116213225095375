import { useMemo } from "react";
import { GameModuleState } from "../../modules/game";

type Props = {
    gameRun: {
        choices: {key: string, value: number}[],
        rules: string[]
    },
    game: GameModuleState
}

const PointsElement: React.FC<Props> = ({ gameRun, game }) => {

    const points = useMemo(() => {
        return gameRun.choices.reduce((prev, { key, value }) => {
            const step = game.steps.find(s => s.id === key);

            if (step) {
                const action = step.actions?.find(a => a.value === value);

                if (action?.points) {
                    return {
                        catA: prev.catA + (action.points.catA || 0),
                        catP: prev.catP + (action.points.catP || 0),
                        catZ: prev.catZ + (action.points.catZ || 0)
                    }
                }
            }

            return prev;
        }, {
            catA: 10,
            catP: 10,
            catZ: 10
        })
    }, [game, gameRun])

    const stars = useMemo(() => {
        return {
            starsA: pointToStars(points.catA),
            starsP: pointToStars(points.catP),
            starsZ: pointToStars(points.catZ)
        }
    }, [points])

    return <div className="points">
        <div className="point-info" >
            <div className="wrapper cat-1">
                <div className="category-container">
                    <span className="category-name">Atmosfera</span>
                    <span className="category-score" aria-label={`${stars.starsA} na 5 gwiazdek`}>
                        <span className="points-numeric">{points.catA}</span>
                        {
                            Array(5).fill('').map((_, index) => {
                                return <span key={index} className={`star star-${index < stars.starsA ? 1 : 0}`}></span>
                            })
                        }
                    </span>
                </div>
            </div>
        </div>
        <div className="point-info">
            <div className="wrapper cat-2">
                <div className="category-container">
                    <span className="category-name">Profesjonalizm</span>
                    <span className="category-score" aria-label={`${stars.starsP} na 5 gwiazdek`}>
                        <span className="points-numeric">{points.catP}</span>
                        {
                            Array(5).fill('').map((_, index) => {
                                return <span key={index} className={`star star-${index < stars.starsP ? 1 : 0}`}></span>
                            })
                        }
                    </span>

                </div>
            </div>
        </div>
        <div className="point-info">
            <div className="wrapper cat-3">
                <div className="category-container">
                    <span className="screen-reader-only">Liczba zabytków</span>
                    <span className="category-name" aria-hidden="true">L.{'\u00A0'}Zabytków</span>
                    <span className="category-score" aria-label={`${stars.starsZ} na 5 gwiazdek`}>
                        <span className="points-numeric">{points.catZ}</span>
                        {
                            Array(5).fill('').map((_, index) => {
                                return <span key={index} className={`star star-${index < stars.starsZ ? 1 : 0}`}></span>
                            })
                        }
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export default PointsElement;


function pointToStars(points: number) {
    if (points <= 5) {
        return 1;
    } else if (points <= 10) {
        return 2;
    } else if (points <= 15) {
        return 3;
    } else if (points <= 20) {
        return 4
    } else {
        return 5;
    }
}
