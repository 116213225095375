import { PopupRequest } from "@azure/msal-browser";
import { CONFIG } from "../../config";
import createAzureProvider from "./create-azure-provider";

const [
    AzureProvider,
    useSetAccessToken
] = createAzureProvider();

const loginRequestParams: PopupRequest = {
    scopes: [...CONFIG.AZURE.BACKEND_APIS]
};

export {
    AzureProvider,
    useSetAccessToken,
    loginRequestParams
}
