import React, { useCallback } from "react";
import { NetworkProvider } from "../network";

type Props = {

}

const HostingContainer: React.FC<Props> = ({children}) => {

    const interceptor = useCallback((config: any) => {
        return config;
    }, [])

    return <NetworkProvider interceptor={interceptor}>
        {children}
    </NetworkProvider>
}

export default HostingContainer;
