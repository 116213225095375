import createStore from "../../utils/react-create-store";
import UserReducer from "./reducer";

export enum USER_ROLE {
    PLAYER = 'player',
    TEACHER = 'teacher',
    ADMIN = 'admin'
}

export type UserModuleState = {
    id?: string,
    name: string,
    role: USER_ROLE
    activeStep: string | null,
    firstRun?: number,
    lastRun?: number,
    activeRun?: number
}

export enum USER_ACTION {
    SET_ACTIVE_STEP = 'user:set-active-step',
    LOGIN = 'user:login',
    LOGOUT = 'user:logout',
    FETCH_SUCCESS = 'user:fetch-success'
}

export type UserModuleAction = {
    type: USER_ACTION,
    payload: any
}

export const initUserState: UserModuleState = {
    role: USER_ROLE.PLAYER,
    name: '',
    activeStep: null
}


const [
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
] = createStore(UserReducer, initUserState, 'netykieta-dev-user');

export {
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
}
