import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { USER_ACTION, useUserModuleDispatch } from "..";
import { getHomeRoute } from "../../../routes/routes";
import { GAME_RUN_ACTION, useGameRunModuleDispatch } from "../../game-run";
import { useLogoutRequest } from "../../network";

export default function useLogout() {

    const dispatch = useUserModuleDispatch();
    const runDispath = useGameRunModuleDispatch()
    const request = useLogoutRequest();
    const history = useHistory();

    const { mutate } = useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries('get-user');

            dispatch({
                type: USER_ACTION.LOGOUT,
                payload: {}
            });

            runDispath({
                type: GAME_RUN_ACTION.RESET,
                payload: {}
            });

            history.replace(getHomeRoute());
        }
    });

    const queryClient = useQueryClient();

    return useCallback(() => {
        mutate();
    }, [mutate]);
}
