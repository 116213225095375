const CONFIG = {

    /**
     * ==================================================================
     * PRODUKCJA
     * ==================================================================
     */
    HOSTING_TYPE: 'azure', // 'classic' | 'azure'
    BACKENT_URL: 'https://netykietabackend-dev.azurewebsites.net/',  // EDU
    AZURE: {
        CLIENT_ID: 'dfdea897-1ffd-42e1-986c-34e61fee4e03', // EDU
        TENANT_ID: '7fb57ac2-1267-4d8b-bd83-8a898116c90c', // EDU
        BACKEND_APIS: []
    },

    /**
     * ==================================================================
     * STAGING
     * ==================================================================
     */
    // HOSTING_TYPE: 'classic', // 'classic' | 'azure'
    // BACKENT_URL: 'https://netykieta-server.borek224.usermd.net/',

    /**
     * ==================================================================
     * DEV - HOSTING
     * ==================================================================
     */

    // HOSTING_TYPE: 'classic', // 'classic' | 'azure'
    // BACKENT_URL: 'https://netykieta-server-dev.borek224.usermd.net/',

    /**
     * ==================================================================
     * DEV - AZURE
     * ==================================================================
     */

    // HOSTING_TYPE: 'azure', // 'classic' | 'azure'
    // BACKENT_URL: 'https://mb-test-backend.azurewebsites.net/',
    // AZURE: {
    //     CLIENT_ID: '0bb12c7e-f2d0-4b21-8a61-7e95395c4239', // mborecki:localhost:3000
    //     TENANT_ID: 'c9f93925-4ba4-4299-9916-dd0cdd6bbfc6', // mborecki
    //     BACKEND_APIS: []
    // }
}

export {
    CONFIG
}
