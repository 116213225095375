import React from "react";
import DemoLoginForm from "../../components/demo-login-form";
import useIsLogged from "../user/hooks/use-is-logged";

type Props = {

}

const HostingAuthorization: React.FC<Props> = ({ children }) => {
    const isLogged = useIsLogged();

    return <>
        {isLogged && <>{children}</>}
        {!isLogged && <DemoLoginForm />}
    </>
}

export default HostingAuthorization;
