import React from "react";
import { Link, useParams } from "react-router-dom";
import ErrorInfo from "../../../components/error";
import Loader from "../../../components/loader";
import useClassList from "../../../modules/teacher/hooks/use-class-list";
import { getSummaryRoute } from "../../routes";
import './style.scss';

export default function ClassListPage() {

    const { id } = useParams<{ id: string }>();
    const { data, isLoading, error } = useClassList(id);

    return <main className="class-list-page">
        <div className="summary-top-gfx"></div>
        <h1 className="heading-1">Lista uczniów</h1>
        <div className="content">
            {isLoading && <Loader />}
            {!isLoading && <>
                {error && <ErrorInfo />}
                {data && <table>
                    <thead>
                        <tr>
                            <td>ID Ucznia</td>
                            <td className="raport-1">Pierwsza gra</td>
                            <td className="raport-2">Ostatnia gra</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((u: any, index) => {
                            return <tr key={index}>
                                <td>{u.name}</td>
                                <td className="raport-1"> {u.firstRun && <Link aria-label={`${u.name}`} to={getSummaryRoute(u.firstRun)}>RAPORT</Link>}</td>
                                <td className="raport-2">{u.lastRun && <Link aria-label={`${u.name}`} to={getSummaryRoute(u.lastRun)}>RAPORT</Link>}</td>
                            </tr>
                        })}
                    </tbody>
                </table>}
            </>}
        </div>
    </main>
}
